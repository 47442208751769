export default {
  owner: [
    {
      required: true,
      message: 'Nama pemilik tidak boleh kosong!',
    },
  ],
  name: [{ required: true, message: 'Nama distributor tidak boleh kosong!' }],
  customerCodes: {
    code: [
      { required: true, message: 'Kode tidak boleh kosong!' },
      {
        pattern: /^[0-9]*$/,
        message: 'Kode tidak boleh memuat karakter selain angka',
        required: true,
      },
    ],
  },
  email: [
    {
      required: true,
      message: 'Email tidak boleh kosong!',
    },
    {
      type: 'email',
      message: 'Format email salah',
    },
  ],
  phone: [
    {
      required: true,
      message: 'Nomor Telepon tidak boleh kosong!',
    },
    {
      max: 12,
      min: 0,
      message: 'Maximal nomor telepon 12 angka',
    },
  ],
  npwp: [
    {
      required: true,
      message: 'NPWP tidak boleh kosong!',
    },
    {
      pattern: /^[0-9\.\-]*$/,
      message: `NPWP tidak boleh memuat karakter selain angka,'.',dan '-'`,
    },
  ],
  wilayah_id: [
    {
      required: true,
      message: 'Kabupaten/Kota tidak boleh kosong!',
    },
  ],
  address: [
    {
      required: true,
      message: 'Alamat tidak boleh kosong!',
    },
  ],
  foto_ktp: [
    {
      required: true,
      message: 'Maximum upload file size 2 MB!',
    },
  ],
  foto: [
    {
      required: true,
      message: 'Maximum upload file size 2 MB!',
    },
  ],
  logo: [
    {
      required: true,
      message: 'Maximum upload file size 2 MB!',
    },
  ],
  document: [
    {
      required: true,
      message: 'Document tidak boleh kosong!',
    },
  ],
  vendors: [
    {
      required: true,
      message: 'Large Toko tidak boleh kosong!',
    },
  ],
}
