<template>
  <a-form
    ref="formRef"
    class="myform"
    :model="formState"
    :rules="rules"
    :wrapper-col="{ span: 14 }"
    :scroll-to-first-error="true"
  >
    <a-row class="form-row">
      <a-col :sm="24">
        <a-form-item
          label="Kode"
          label-align="left"
          :label-col="{ sm: { span: 4 } }"
          :wrapper-col="{ sm: { span: 24 - 4 } }"
        >
          <a-input
            placeholder="Kode"
            width="100%"
            v-model:value="formState.code"
            :disabled="!isNew"
            type="number"
          />
        </a-form-item>
      </a-col>
    </a-row>

    <a-form-item :label-col="{ sm: { span: 5 } }" :wrapper-col="{ sm: { span: 24 - 5 } }">
      <a-col :sm="{ span: 24, offset: 5 }">
        <a-row class="myform-vertical" layout="vertical" v-if="customerCodes">
          <template v-for="(item, index) in customerCodes.filter(i => !i.is_deleted)" :key="index">
            <a-input
              size="small"
              type="hidden"
              v-model:value="item.vendor_id"
              :disabled="formState.readOnly || item.id !== null"
            />
            <a-row class="form-row">
              <a-col :sm="5">
                <a-form-item
                  label="Kode Referensi"
                  label-align="left"
                  :name="['customerCodes', index, 'code']"
                  :rules="{
                    required: true,
                    message: 'Kode tidak boleh diisi selain angka!',
                  }"
                  :help="
                    Array.isArray(item.errors)
                      ? item.errors.find(i => i.field === 'code')
                        ? item.errors.find(i => i.field === 'code').message
                        : undefined
                      : undefined
                  "
                  :validate-status="
                    Array.isArray(item.errors)
                      ? item.errors.find(i => i.field === 'code') !== null
                        ? 'error'
                        : undefined
                      : undefined
                  "
                >
                  <a-input
                    size="small"
                    type="number"
                    :min="0"
                    onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                    stylea="width: 150px"
                    v-model:value="item.code"
                    :disabled="formState.readOnly || item.id !== null"
                  />
                </a-form-item>
              </a-col>
              <a-col :sm="5">
                <a-form-item label="Tanggal Mulai" label-align="left">
                  <a-date-picker
                    size="small"
                    type="text"
                    stylea="width: 150px;"
                    :disabled-date="c => disabledStartDated(c, item.end_date)"
                    v-model:value="item.begin_date"
                    :disabled="formState.readOnly || item.id !== null"
                  />
                </a-form-item>
              </a-col>
              <a-col :sm="5">
                <a-form-item label="Tanggal Selesai" label-align="left">
                  <a-date-picker
                    size="small"
                    type="text"
                    stylea="width: 150px"
                    :disabled-date="c => disabledEndDated(item.begin_date, c)"
                    v-model:value="item.end_date"
                    :disabled="formState.readOnly || item.id !== null"
                  />
                </a-form-item>
              </a-col>
              <a-col :sm="5">
                <a-form-item
                  label="Brand"
                  label-align="left"
                  :name="['customerCodes', index, 'brand_id']"
                  :rules="{
                    required: true,
                    message: 'brand tidak boleh kosong!',
                  }"
                  required
                  :help="
                    Array.isArray(item.errors)
                      ? item.errors.find(i => i.field === 'brand_id')
                        ? item.errors.find(i => i.field === 'brand_id').message
                        : undefined
                      : undefined
                  "
                  :validate-status="
                    Array.isArray(item.errors)
                      ? item.errors.find(i => i.field === 'brand_id') !== null
                        ? 'error'
                        : undefined
                      : undefined
                  "
                >
                  <filter-brand
                    size="small"
                    :mode="null"
                    type="text"
                    style="width: 100%"
                    v-model:value="item.brand_id"
                    :disabled="formState.readOnly || item.id !== null"
                  ></filter-brand>
                </a-form-item>
              </a-col>

              <a-col :sm="4">
                <a-form-item label=" " label-align="left">
                  <a-button
                    @click="removeRow(item)"
                    size="small"
                    type="danger"
                    style="margin-top: 22px"
                    :disabled="formState.readOnly"
                    ><i class="fa fa-remove"></i
                  ></a-button>
                </a-form-item>
              </a-col>
            </a-row>
          </template>
        </a-row>
        <!-- add function -->
        <template v-if="!formState.readOnly">
          <a-form-item
            label-align="left"
            :wrapper-col="{ sm: { span: 24 - 4 - 8 } }"
            :colon="false"
          >
            <a-button type="primary" @click="addCode"
              ><i class="fa fa-plus"></i> Tambah Kode Referensi</a-button
            >
          </a-form-item>
        </template>
      </a-col>
    </a-form-item>

    <a-row class="form-row">
      <a-col :md="12" :sm="24">
        <a-form-item
          label="Nama Toko"
          label-align="left"
          :label-col="{ sm: { span: 8 } }"
          :wrapper-col="{ sm: { span: 24 - 8 } }"
          required
          has-feedback
          name="name"
        >
          <a-input
            placeholder="Nama Toko"
            v-model:value="formState.name"
            :disabled="formState.readOnly"
            required
          />
        </a-form-item>
      </a-col>
      <a-col :md="12" :sm="24">
        <a-form-item
          label="Nama Pemilik"
          label-align="left"
          :label-col="{ sm: { span: 8 } }"
          :wrapper-col="{ sm: { span: 24 - 8 } }"
          required
          has-feedback
          name="owner"
        >
          <a-input
            placeholder="Nama Pemilik"
            v-model:value="formState.owner"
            :disabled="formState.readOnly"
          />
        </a-form-item>
      </a-col>
    </a-row>

    <a-row class="form-row">
      <a-col :md="12" :sm="24">
        <a-form-item
          label="NPWP"
          label-align="left"
          :label-col="{ sm: { span: 8 } }"
          :wrapper-col="{ sm: { span: 24 - 8 } }"
          required
          name="npwp"
        >
          <a-input placeholder="NPWP" v-model:value="formState.npwp" :disabled="formState.readOnly"
        /></a-form-item>
      </a-col>
      <a-col :md="12" :sm="24">
        <a-form-item
          label="Email"
          label-align="left"
          :label-col="{ sm: { span: 8 } }"
          :wrapper-col="{ sm: { span: 24 - 8 } }"
          required
          name="email"
        >
          <a-input
            placeholder="Contoh : example@mail.com"
            v-model:value="formState.email"
            :disabled="formState.readOnly"
          >
          </a-input>
        </a-form-item>
      </a-col>
    </a-row>

    <a-row class="form-row">
      <a-col :md="12" :sm="24">
        <a-form-item
          label="Nomor Telepon"
          label-align="left"
          :label-col="{ sm: { span: 8 } }"
          :wrapper-col="{ sm: { span: 24 - 8 } }"
          required
          name="phone"
        >
          <a-input
            addon-before="+62"
            placeholder="Ex : 81xxxxxxxxx"
            v-model:value="formState.phone"
            :disabled="formState.readOnly"
            onkeypress="return event.charCode >= 48 && event.charCode <= 57"
            pattern="^[1-9][0-9]*$"
            required
            oninput="if(!this.value.match('^[1-9][0-9]*$'))this.value='';"
            _type="text"
            maxlength="13"
          />
        </a-form-item>
      </a-col>
      <a-col :md="12" :sm="24">
        <a-form-item
          label="Nomor Telepon 2"
          label-align="left"
          :label-col="{ sm: { span: 8 } }"
          :wrapper-col="{ sm: { span: 24 - 8 } }"
        >
          <a-input
            addon-before="+62"
            v-model:value="formState.phone2"
            placeholder="Ex : 81xxxxxxxxx"
            :disabled="formState.readOnly"
            onkeypress="return event.charCode >= 48 && event.charCode <= 57"
            pattern="^[1-9][0-9]*$"
            required
            oninput="if(!this.value.match('^[1-9][0-9]*$'))this.value='';"
            _type="text"
            maxlength="13"
          />
        </a-form-item>
      </a-col>
    </a-row>

    <a-row class="form-row">
      <a-col :md="12" :sm="24">
        <a-form-item
          label="Segment"
          label-align="left"
          :label-col="{ sm: { span: 8 } }"
          :wrapper-col="{ sm: { span: 24 - 8 } }"
          required
        >
          <a-select v-model:value="formState.type" :disabled="formState.readOnly">
            <a-select-option :key="2">Toko</a-select-option>
            <a-select-option :key="1105">Large Toko</a-select-option>
          </a-select>
        </a-form-item>
      </a-col>
      <a-col :md="12" :sm="24">
        <a-form-item
          label="Target Volume"
          label-align="left"
          :label-col="{ sm: { span: 8 } }"
          :wrapper-col="{ sm: { span: 24 - 8 } }"
        >
          <a-input
            v-model:value="formState.target_volume"
            addon-after="TON"
            width="100%"
            type="number"
            :min="0"
            placeholder="Target Volume"
            :disabled="formState.readOnly"
          />
        </a-form-item>
      </a-col>
    </a-row>

    <a-row class="form-row">
      <a-col :md="12" :sm="24">
        <a-form-item
          label="Foto KTP"
          label-align="left"
          :label-col="{ sm: { span: 8 } }"
          :wrapper-col="{ sm: { span: 24 - 8 } }"
          required
          name="foto_ktp"
        >
          <template v-if="!formState.readOnly">
            <a-input
              required
              v-if="!foto_ktp || foto_ktp === 'null'"
              type="file"
              accept="image/png, image/gif, image/jpeg"
              @change="e => onFileChange(e, 'ktp')"
            ></a-input>
            <a-button
              v-else
              @click="
                () => {
                  foto_ktp = null
                }
              "
              >Hapus ktp</a-button
            >
          </template>
          <img
            v-if="foto_ktp !== null"
            style="display: block; max-width:150px;
                  width: auto;
                  height: auto;"
            :src="typeof foto_ktp === 'string' ? foto_ktp : foto_ktp_image"
          />
        </a-form-item>
      </a-col>
      <a-col :md="12" :sm="24">
        <a-form-item
          label="Foto Toko"
          label-align="left"
          :label-col="{ sm: { span: 8 } }"
          :wrapper-col="{ sm: { span: 24 - 8 } }"
        >
          <template v-if="!formState.readOnly">
            <a-input
              required
              v-if="!foto || foto === null"
              type="file"
              accept="image/png, image/gif, image/jpeg"
              @change="e => onFileChange(e, 'foto')"
            ></a-input>
            <a-button
              v-else
              @click="
                () => {
                  foto = null
                }
              "
              >Hapus foto</a-button
            >
          </template>
          <img
            v-if="foto !== null"
            style="display: block; max-width:150px;
                  width: auto;
                  height: auto;"
            :src="typeof foto === 'string' ? foto : foto_image"
          />
        </a-form-item>
      </a-col>
    </a-row>

    <a-row class="form-row">
      <a-col :md="12" :sm="24">
        <a-form-item
          label="Logo Toko"
          label-align="left"
          :label-col="{ sm: { span: 8 } }"
          :wrapper-col="{ sm: { span: 24 - 8 } }"
        >
          <template v-if="!formState.readOnly">
            <a-input
              required
              v-if="!logo || logo === null"
              type="file"
              accept="image/png, image/gif, image/jpeg"
              @change="e => onFileChange(e, 'logo')"
            ></a-input>
            <a-button
              v-else
              @click="
                () => {
                  logo = null
                }
              "
              >Hapus foto</a-button
            >
          </template>
          <img
            v-if="foto_ktp !== null"
            style="display: block; max-width:150px;
                  width: auto;
                  height: auto;"
            :src="typeof logo === 'string' ? logo : logo_image"
          />
        </a-form-item>
      </a-col>
      <a-col :md="12" :sm="24">
        <a-form-item
          label="Dokumen Toko"
          label-align="left"
          :label-col="{ sm: { span: 8 } }"
          :wrapper-col="{ sm: { span: 24 - 8 } }"
        >
          <template v-if="!formState.readOnly">
            <a-input
              required
              v-if="!document || document === null"
              type="file"
              @change="e => onFileChange(e, 'document')"
            ></a-input>
            <a-button
              v-else
              @click="
                () => {
                  document = null
                }
              "
              >Hapus Dokumen</a-button
            ><br />
          </template>
          <a
            v-if="
              document !== null &&
                document !== undefined &&
                typeof document === 'string' &&
                document !== 'null'
            "
            :href="!document ? 'javascript.void(0)' : document"
            target="_blank"
            >Lihat Dokumen</a
          >
        </a-form-item>
      </a-col>
    </a-row>

    <a-row class="form-row">
      <a-col :sm="24">
        <a-form-item
          label="Alamat"
          label-align="left"
          :label-col="{ sm: { span: 4 } }"
          :wrapper-col="{ sm: { span: 24 - 4 } }"
          required
          name="address"
        >
          <a-input
            placeholder="Alamat"
            v-model:value="formState.address"
            :disabled="formState.readOnly"
          />
        </a-form-item>
      </a-col>
    </a-row>

    <a-row class="form-row">
      <a-col :sm="24">
        <a-form-item
          label="Kabupaten/Kota"
          label-align="left"
          :label-col="{ sm: { span: 4 } }"
          :wrapper-col="{ sm: { span: 24 - 4 } }"
        >
          <filter-wilayah-semua
            v-model:value="formState.wilayah_id"
            label="fullname"
            :disabled="formState.readOnly"
             :join-options="
              formState.wilayah_id
                ? [{ id: formState.wilayah_id, fullname: formState.wilayah }]
                : []
            "
          ></filter-wilayah-semua>
        </a-form-item>
      </a-col>
    </a-row>

    <a-row class="form-row">
      <a-col :sm="24">
        <a-form-item
          label="Keterangan"
          label-align="left"
          :label-col="{ sm: { span: 4 } }"
          :wrapper-col="{ sm: { span: 24 - 4 } }"
        >
          <a-input
            placeholder="Keterangan"
            width="100%"
            v-model:value="formState.note"
            :disabled="formState.readOnly"
          />
        </a-form-item>
      </a-col>
    </a-row>

    <a-row class="form-row">
      <a-col :sm="24">
        <a-form-item
          label="Pilih Large Toko"
          label-align="left"
          :label-col="{ sm: { span: 4 } }"
          :wrapper-col="{ sm: { span: 24 - 4 } }"
          required
          name="vendors"
          allow-clear
        >
          <filter-lt
            show-search
            for-input
            v-model:value="formState.vendors"
            :join-options="formState.vendorRaw"
            label="fullname"
            style="width: 100%"
            :disabled="formState.readOnly"
          ></filter-lt>
        </a-form-item>
      </a-col>
    </a-row>
  </a-form>
</template>
<script>
import { ref, reactive, inject, computed, nextTick, toRef, onBeforeMount, toRaw, toRefs } from 'vue'
import apiClient from '@/services/axios'
import FilterBrand from '@/components/filter/FilterBrand'
import FilterDistributor from '@/components/filter/FilterDistributor'
import { message } from 'ant-design-vue'
import { useDisabledDate } from '@/composables/DisabledDate'
import FilterLt from '@/components/filter/FilterLt'
import rules from './rules'
import FilterWilayahSemua from '@/components/filter/FilterWilayahSemua'

export default {
  name: 'ModalMasterDataJaringanLt',
  components: {
    FilterWilayahSemua,
    FilterBrand,
    // FilterDistributor,
    FilterLt,
  },
  setup(props, { emit }) {
    const segment = ref(1)
    const { disabledStartDated, disabledEndDated } = useDisabledDate()
    const defWrapCol = reactive({ md: { span: 16, offset: 4 }, sm: { span: 24 } })
    const formState = inject('formState')
    const foto_ktp = toRef(formState.value, 'foto_ktp')
    const foto = toRef(formState.value, 'foto')
    const logo = toRef(formState.value, 'logo')
    const document = toRef(formState.value, 'document')
    const readOnly = false
    const visible = ref(false)
    const dataWilayah = ref([])
    const phone = ref('')

    const fotos = reactive({
      foto_ktp_image: null,
      foto_image: undefined,
      logo_image: undefined,
    })

    const formRef = ref('')

    const customerCode = ref({
      id: null,
      brand_id: null,
      vendor_id: null,
      code: null,
      begin_date: null,
      end_date: null,
    })

    const customerCodes = toRef(formState.value, 'customerCodes')

    onBeforeMount(() => {
      if (formState.value.customerCodes === undefined || formState.value.customerCodes === null) {
        formState.value.customerCodes = []
      }
    })

    const generateEmail = () => console.log('genraete')

    const isNew = computed(() => formState.value.state === 'new')

    const urlUpload = apiClient.defaults.baseURL + '/api/upload'
    // filelist upload, foto distributor, foto, foto, document
    const fKtp = ref([])
    const ktp = ref(true)
    const ifFoto = ref(true)
    const ifLogo = ref(true)
    const ifDokumen = ref(true)

    // const action = file => {
    //   var FormData = require('form-data')
    //   var data = new FormData()
    //   return new Promise((resolve, reject) => {
    //     return resolve({
    //       data: { satu: 'https:\/\/files.forca.id\/f\/20f2a783-8d2f-4896-988f-86cfb0d8c93a.png' },
    //     })
    //   })
    // }

    const onFileChange = async (e, type) => {
      var files = e.target.files || e.dataTransfer.files
      console.log(files.length, type)
      if (!files.length) return
      var filesize = (files[0].size / 1024 / 1024).toFixed(4) // MB
      if (filesize > 2.0) {
        message.warning('Ukuran harus di bawah 2MB!', 5.0)
        if ('ktp' === type) {
          ktp.value = false
        } else if ('foto' === type) {
          ifFoto.value = false
        } else if ('logo' === type) {
          ifLogo.value = false
        } else if ('document' === type) {
          ifDokumen.value = false
        }
        await nextTick()
        if ('ktp' === type) {
          ktp.value = true
        } else if ('foto' === type) {
          ifFoto.value = true
        } else if ('logo' === type) {
          ifLogo.value = true
        } else if ('document' === type) {
          ifDokumen.value = true
        }
        return
      }

      if ('ktp' === type) {
        foto_ktp.value = files[0]
        imageToBase64(foto_ktp.value, toRefs(fotos).foto_ktp_image)
      } else if ('foto' === type) {
        foto.value = files[0]
        imageToBase64(foto.value, toRefs(fotos).foto_image)
      } else if ('logo' === type) {
        logo.value = files[0]
        imageToBase64(logo.value, toRefs(fotos).logo_image)
      } else if ('document' === type) {
        document.value = files[0]
      }
    }

    const imageToBase64 = (file, to) => {
      var reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => {
        to.value = reader.result
      }
      reader.onerror = function(error) {
        console.log('Error: ', error)
      }
    }

    const addCode = () => {
      if (customerCodes.value.filter(v => v.code === null).length > 0) {
        message.warning('Pastikan kode referensi terisi!')
        return
      }
      isAddCode.value = false
      const m = Object.assign({}, customerCode.value)
      // m.id = 1
      customerCodes.value.push(m)
      // resetCode()
    }

    const removeRow = item => {
      const index = customerCodes.value.indexOf(item)
      if (item.id === null) customerCodes.value.splice(index, 1)
      else {
        item.is_deleted = true
      }
    }

    const isAddCode = ref(true)
    const saveCode = () => {
      isAddCode.value = true
      resetCode()
    }
    const batalCode = () => {
      isAddCode.value = true
    }
    const resetCode = () => {
      customerCode.value = Object.assign(
        {},
        {
          id: null,
          brand_id: null,
          vendor_id: null,
          code: null,
          begin_date: null,
          end_date: null,
        },
      )
    }

    return {
      segment,
      defWrapCol,
      formState,
      generateEmail,
      dataWilayah,
      isNew,
      visible,
      addCode,
      customerCode,
      customerCodes,
      removeRow,
      fKtp,
      urlUpload,
      //  action,
      onFileChange,
      ktp,
      ifFoto,
      ifLogo,
      ifDokumen,
      foto_ktp,
      foto,
      logo,
      document,
      imageToBase64,
      isAddCode,
      saveCode,
      batalCode,
      formRef,
      rules,
      ...toRefs(fotos),
      disabledEndDated,
      disabledStartDated,
    }
  },
}
</script>
<style lang="scss" scoped>
@import '@/css/form.scss';
</style>
